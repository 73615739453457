.sl-section {
    @include props-margin;
    @include margin;

    &__content {
        @include props-padding;
        @include padding;
    }
}

.sl-section {
    --margin-top: var(--bazis-margin-8x);

    &__subtitle {
        margin-bottom: var(--bazis-margin-3x);
        text-transform: uppercase;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 1px;
        color: var(--bazis-text-color-secondary);
    }

    app-bid-order .sl-form & {
        max-width: 780px;

        @media (max-width: 1024px) {
            max-width: 100%;
        }
    }

    app-summary & {
        --margin-top: calc(var(--bazis-margin-6x) * 2);

        &:first-child {
            --margin-top: 40px;
        }

        @media (max-width: 1024px) {
            &:first-child {
                --margin-top: var(--bazis-margin-6x);
            }
        }
    }
}

.sl-header-section.bazis-block {
    --margin-top: var(--bazis-margin-5x);
    --margin-bottom: calc(var(--bazis-margin-6x) * 2);
    --padding-top: var(--bazis-padding-8x);
    --padding-bottom: var(--bazis-padding-8x);
    --padding-start: 0;
    --padding-end: 0;

    .bazis-block__content {
        display: flex;

        &_main {
            --padding-start: var(--bazis-padding-8x);
            --padding-end: var(--bazis-padding-8x);

            h5 {
                margin: 0 0 var(--bazis-margin-4x);
            }
        }

        &_end {
            --width: 340px;
            --padding-start: var(--bazis-padding-8x);
            --padding-end: var(--bazis-padding-8x);
            --margin-start: 0;
        }
    }
}
