bazis-toast {
    --max-height: calc(100vh - var(--bazis-padding-8x));

    @media screen and (max-width: 576px) {
        --max-width: 100%;
        --width: auto;

        &::part(group) {
            left: 12px;
        }
    }
}
