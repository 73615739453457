.sl-avatar_userpic {
    --width: 48px;
    --height: 48px;
    --background: var(--bazis-background);
    --color: var(--bazis-text-color-secondary);
    --icon-size: 24px;

    float: left;
    margin-right: var(--bazis-margin-4x);
    border: 1px solid var(--bazis-border-color);
}
