.sl-symbol-control {
    --border-width: 1px;

    .bazis-control {
        &__field {
            text-align: center;

            &-group {
                margin: 0 var(--bazis-margin-2x);
                width: 36px;

                &-wrapper {
                    justify-content: center;
                }
            }
        }
    }
}
