.bazis-blocking-message {
    @media (max-width: 1024px) {
        padding-top: var(--bazis-padding-3x);
        padding-bottom: var(--bazis-padding-3x);
    }

    @media screen and (max-width: 576px) {
        &__item > .bh-overhide.bh-text-center {
            text-align: left !important;
        }
    }
}
