.bazis-control {
    --border-color-focus: var(--bazis-color-action);
    --border-color-active: var(--bazis-color-action);

    // .sl-form bazis-autocomplete > & {
    //     --margin-bottom: var(--bazis-margin-1x);
    // }

    .sl-form_oneline &,
    .sl-grid_table bazis-col &,
    .bazis-block_accent .bazis-grid_form > bazis-row:last-child,
    .sl-toolbar_table &,
    .bazis-block_search &,
    .sl-toolbar_table-header &,
    .sl-toolbar_notification & {
        --margin-bottom: 0;
    }

    &__field-group {
        &__start {
            padding: 0 var(--padding-field-end) 0 var(--padding-field-start);
        }
    }

    @media (max-width: 992px) {
        &--activated {
            bazis-autocomplete &,
            app-search-filter & {
                --border-radius: 0;
                --border-width: 1px 0 1px 0;
                position: fixed;
                z-index: 1002;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                height: 100vh;

                .sl-form & {
                    --margin-bottom: 0;
                }

                .bazis-eraser {
                    display: none;
                }

                bazis-label {
                    display: none;
                }

                .bazis-control__field-group--activated {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                }

                .bazis-list_control-dd {
                    --max-height: calc(100vh - 36px - 1px);
                    --margin-top-list: 1px;
                    --padding-top-list: 0;
                    --padding-end-list: var(--bazis-padding-5x);
                    --padding-start-list: var(--bazis-padding-5x);
                    --border-radius-list: 0;

                    --border-width: 0 0 1px 0;
                    --border-color: var(--bazis-border-color);
                    --border-style: solid;
                    --padding-end: 0;
                    --padding-start: 0;
                    --padding-top: var(--bazis-padding-6x);
                    --padding-bottom: var(--bazis-padding-5x);

                    --max-height-infinite-scroll-content: calc(
                        var(--max-height) - var(--padding-bottom-list) - var(--padding-top-list)
                    );
                    height: var(--max-height);

                    .bazis-infinite-scroll-content {
                        margin-right: calc(var(--padding-end-list) * -1);
                    }

                    .bazis-item {
                        --background-hover: var(--background);

                        &.bazis-radio,
                        &.bazis-checkbox {
                            border-bottom: 1px solid var(--border-color);
                            // margin-top: 0; // не обязательно?
                        }
                    }
                }
            }
        }
    }

    @media (max-width: 576px) {
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col:first-child & {
            --margin-bottom: var(--bazis-margin-6x);
        }
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col:last-child &,
        .sl-section .bazis-grid_form > bazis-row:last-child bazis-col.bh-next-empty & {
            --margin-bottom: 0;
        }

        .sl-form app-input-map-point bazis-col:first-child & {
            --margin-bottom: var(--bazis-margin-6x);
        }
    }
}

bazis-input-options-button {
    display: block;
    margin-bottom: var(--bazis-margin-6x);
}
