.bazis-contact {

    &__name {
        margin: 0 0 var(--bazis-margin-1x);

        .sl-accordion_profile & {
            margin: 0 0 var(--bazis-margin-2x);
        }
    }
    &__position {
        margin: 0 0 var(--bazis-margin-2x);
    }
    &__mail {
        margin: 0 0 var(--bazis-margin-1x);

        .sl-accordion_profile & {
            margin: 0 0 var(--bazis-margin-2x);
        }
    }
    &__phone {
        margin: 0;
    }

    & + & {
        --margin-top: var(--bazis-margin-5x);
    }

    + hr {
        --margin-top: var(--bazis-margin-5x);
        --margin-bottom: var(--bazis-margin-5x);
    }
}
