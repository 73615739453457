.bazis-alert {
    --padding-start: var(--bazis-padding-8x);
    --padding-end: var(--bazis-padding-8x);
    --padding-top: var(--bazis-padding-8x);
    --padding-bottom: var(--bazis-padding-8x);

    --max-height: calc(100vh - var(--bazis-padding-8x));

    &__message {
        color: var(--bazis-text-color);
    }

    .sl-form .bazis-map-wrapper {
        --height: 180px;
    }
}
