.sl-section-sign {
    margin-bottom: var(--bazis-margin-6x);

    &:last-child {
        margin-bottom: 0;
    }

    > bazis-icon {
        float: left;
        margin-right: var(--bazis-margin-5x);

        + * {
            overflow: hidden;
        }
    }

    h6 {
        --bazis-h6-margin: 0 0 var(--bazis-margin-1x);
    }

    .bazis-block_accent & {
        &:not(&:last-child) {
            margin-bottom: 40px;
        }

        > bazis-icon {
            margin-right: var(--bazis-margin-8x);
        }

        @media (max-width: 1024px) {
            margin-bottom: var(--bazis-margin-6x);

            > bazis-icon {
                float: none;
                margin-right: 0;
                margin-bottom: var(--bazis-margin-5x);

                + * {
                    overflow: initial;
                }
            }
        }
    }

    // .bazis-modal__content & {
    //     // padding-left: 0;
    //     // padding-bottom: var(--bazis-padding-6x);

    //     > bazis-icon {
    //         // float: left;
    //         // margin-right: var(--bazis-margin-8x);
    //     }

    //     p {
    //         margin-top: 0;
    //         margin-bottom: 2px;
    //         font-weight: 500;

    //         + bazis-link,
    //         + a {
    //             font-weight: 400;
    //         }
    //     }
    // }

    // @media (max-width: 1024px) {
    //     margin-bottom: var(--bazis-margin-6x);

    //     > bazis-icon {
    //         float: none;
    //         margin-right: 0;
    //         margin-bottom: var(--bazis-margin-5x);

    //         + * {
    //             overflow: initial;
    //         }
    //     }
    // }
}
