app-summary {
    @media (max-width: 576px) {
        > bazis-toolbar {
            &:first-child {
                flex-direction: column-reverse;
                align-items: flex-start;


                // &::part(start),
                // &::part(end) {
                //     width: 100%;
                // }
                &::part(end) {
                    margin: calc(var(--bazis-margin-3x) * 3) 0 var(--bazis-margin-3x);
                }

                bazis-interrogation {
                    --margin-start: 0;
                }
                h1 {
                    --bazis-h1-margin: 0 0 var(--bazis-margin-4x);
                }
            }
        }

        .bazis-map-circle {
            float: none;
            margin: 0 auto var(--bazis-margin-6x);
            max-width: 320px;
            width: 100%;
            height: 320px;

            .bazis-map {
                clip-path: none;
                border-radius: 50%;
            }
        }

        display: block;
        margin-bottom: 270px;
    }

    @media (max-width: 360px) {
        .bazis-map-circle {
            max-width: 280px;
            width: 100%;
            height: 280px;
        }
    }
}
