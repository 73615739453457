bazis-map {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
}

// implemented
.bazis-map-wrapper {
    --height: 336px;

    app-input-map-point & {
        margin-bottom: var(--bazis-margin-6x);

        &.bh-no-margin {
            margin-bottom: 0;
        }
    }

    &.sl-map-wrapper_small {
        --height: 260px;
        --margin-bottom: var(--bazis-margin-8x);
    }

    app-order-route-widget & {
        --height: 400px;
    }
}

.bazis-map-circle {
    margin-left: var(--bazis-margin-8x);
}

.sl-map-wrapper_dd {
    @extend .bazis-map-wrapper;
    --height: 100px;
    --width: 332px;
    --margin-top: var(--bazis-margin-2x);
    border: 1px solid var(--bazis-border-color);
    border-radius: var(--bazis-border-radius);

    // @media (max-width: 1180px) {
    //     --height: 106px;
    // }
}

@media (max-width: 1024px) {
    .bazis-map-circle {
        margin-left: var(--bazis-margin-6x);
    }
}

@media (max-width: 576px) {
    // app-route-widget .bazis-map-wrapper,
    app-order-route-widget .bazis-map-wrapper {
        --height: 400px;
        // border: 1px solid var(--bazis-border-color);
        // border-radius: 8px;

        // .bazis-map {
        //     border-radius: 8px;
        // }

        .sl-open-btn,
        .sl-mobile-content {
            position: absolute;
            top: 8px;
            left: 8px;
            z-index: 40;
        }

        .sl-mobile-content {
            right: 8px;
        }

        .sl-mobile-content:not(.bazis-modal .sl-mobile-content) {
            display: none;
        }
    }

    // .sl-map-wrapper_dd {
    //     --height: 72px;
    // }
}
