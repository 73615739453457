.bazis-block {
    --padding-top: var(--bazis-padding-6x);

    &__content {
        &_end {
            --padding-start: 40px;
            --margin-start: var(--bazis-margin-6x);
            flex-shrink: 0;
        }
    }

    &_accent {
        --margin-bottom: var(--bazis-margin-6x);

        .bazis-block & {
            .sl-table_clear td {
                --padding-top: 2px;
                --padding-bottom: 2px;
            }
        }

        .sl-profile-page & {
            --padding-top: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-4x);
            --padding-start: var(--bazis-padding-6x);
            --padding-end: var(--bazis-padding-6x);
            --margin-top: var(--bazis-margin-4x);
            --margin-bottom: var(--bazis-margin-4x);
        }

        &.sl-block_large {
            --margin-bottom: 40px;
        }

        app-legal-document & {
            --margin-top: var(--bazis-margin-3x);
            --padding-top: var(--bazis-padding-4x);
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-4x);

            a {
                font-weight: 500;
                letter-spacing: 0.25px;
                text-decoration: none;
            }
        }

        @media (max-width: 1024px) {
            --padding-top: var(--bazis-padding-6x);
            --padding-bottom: var(--bazis-padding-6x);
            --padding-start: var(--bazis-padding-5x);
            --padding-end: var(--bazis-padding-5x);
        }
    }

    &_secondary {
        .bazis-modal & {
            --padding-top: 10px;
            --padding-bottom: 10px;
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
        }

        .bazis-modal .sl-form & {
            --padding-top: var(--bazis-padding-5x);
            --padding-bottom: var(--bazis-padding-5x);
        }
    }

    aside & {
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);

        &.sl-block_small {
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
        }

        & .bazis-block_secondary {
            --margin-top: var(--bazis-margin-3x);
            --margin-bottom: 0;
            --padding-top: var(--bazis-padding-4x);
            --padding-start: var(--bazis-padding-4x);
            --padding-end: var(--bazis-padding-4x);
            --padding-bottom: var(--bazis-padding-4x);
        }

        &__header {
            --margin-bottom: var(--bazis-margin-3x);
        }
    }
}

.sl-block {
    &_mini {
        --padding-top: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-2x);
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);

        .bazis-list_history & {
            --margin-bottom: 0;
            --margin-top: var(--bazis-margin-3x);
            display: inline-flex;
        }
    }

    &_medium {
        --padding-top: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-6x);
        --padding-start: var(--bazis-padding-5x);
        --padding-end: var(--bazis-padding-5x);
    }

    &_faq {
        --padding-top: var(--bazis-padding-6x);
        --padding-start: var(--bazis-padding-6x);
        --padding-end: var(--bazis-padding-6x);
        --padding-bottom: var(--bazis-padding-8x);
        --border-width: 1px;
        --border-style: solid;
        --border-color: var(--bazis-border-color);
        --border-color-hover: var(--bazis-border-color-dark);

        &:last-child {
            --margin-bottom: 0;
        }
    }
}
