.bazis-page {
    &-header {
        bazis-toolbar {
            + .bazis-list_short-info {
                --margin-top-list: var(--bazis-margin-3x);
            }
        }
    }

    @media (min-width: 1024.98px) {
        &-header {
            bazis-toolbar {
                h1 {
                    margin: 0;
                }
            }
        }
    }

    @media (max-width: 1024px) {
        &-header {
            bazis-toolbar + .bazis-list_short-info {
                --margin-top-list: 0;
            }
        }
    }
}
