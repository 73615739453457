.sl-faq__example {
    --padding-top: 0;
    --padding-start: 0;
    --padding-end: 0;
    --padding-bottom: 0;
    --background: transparent;
    --margin-bottom: 56px;
    --margin-top: var(--bazis-margin-4x);

    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;

    bazis-button {
        margin-left: calc(var(--bazis-margin-4x) * -1);
    }

    @media (max-width: 1024px) {
        --margin-bottom: var(--bazis-margin-6x);
    }

    @media (max-width: 576px) {
        // --padding-top: var(--bazis-padding-4x);
        --padding-start: var(--bazis-padding-4x);
        --padding-end: var(--bazis-padding-4x);
        // --padding-bottom: var(--bazis-padding-4x);
        // --margin-bottom: 0;

        display: block;
        text-align: center;

        > div {
            margin-bottom: 0;
        }

        bazis-button {
            // --padding-end: 16px !important;
            margin-top: var(--bazis-margin-4x);
        }
    }
}

app-faq-general {
    h1 {
        margin-bottom: var(--bazis-margin-3x);
    }

    @media screen and (max-width: 1024px) {
        h1 {
            margin-bottom: var(--bazis-margin-2x);
        }
    }
}

app-faq-subsection-block {
    @media screen and (max-width: 1024px) {
        h1 {
            --bazis-h1-margin: 0 0 var(--bazis-margin-8x);
        }
    }
    @media screen and (max-width: 576px) {
        bazis-button {
            display: block;
        }
    }
}

app-faq-write {
    @media screen and (max-width: 1024px) {
        bazis-button.bh-block {
            display: inline-block;
        }
    }
    @media screen and (max-width: 576px) {
        bazis-button,
        bazis-button.bh-block {
            display: block;
        }
    }
}

app-faq-answer + bazis-button,
app-faq-answer-skeleton + bazis-button {
    margin-top: var(--bazis-margin-5x);
}
