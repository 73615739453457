.sl-list_history__title {
    margin-bottom: var(--bazis-margin-3x);
    font-size: var(--bazis-font-size-p-minor);
    line-height: var(--bazis-line-height-p-major);
    text-transform: uppercase;
    letter-spacing: 2px;
}

@media screen and (max-width: 1024px) {
    app-top-notifications .bazis-block {
        margin: var(--bazis-margin-4x) 0 0;
    }
}
