.bazis-list-page {
    &__tools {
        margin-bottom: var(--bazis-margin-4x);
    }
    @media (max-width: 1024px) {
        &__title {
            align-items: flex-start;
            flex-direction: column;
        }
    }
}
