aside {
    &.bh-sticky {
        overflow-y: auto;
        max-height: calc(100vh - 150px);
        margin-right: calc(var(--bazis-margin-2x) * -1);
        padding-right: var(--bazis-padding-2x);
    }

    app-summary & {
        margin-top: calc(var(--bazis-line-height-h3) + var(--bazis-margin-4x) + 40px);
    }

    &.sl-aside_right {
        @media (max-width: 1024px) {
            margin-top: var(--bazis-margin-8x);
        }
    }
}
