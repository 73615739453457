.bazis-modal {
    --max-width: 880px;

    --padding-bottom-content: calc(var(--bazis-padding-6x) * 2);

    --padding-top-footer: var(--bazis-padding-6x);
    --padding-bottom-footer: calc(var(--bazis-padding-6x) * 2);

    &__content {
        section {
            --margin-top: 0;
            margin-bottom: var(--bazis-margin-8x);
        }
    }

    &__footer {
        border-top: 1px solid var(--bazis-border-color);
    }

    // types
    &_small {
        --padding-top-header: 21px;
    }

    @media screen and (max-width: 1024px) {
        &:not(
                .fullscreen,
                .mobile-bottom,
                .dummy,
                .sl-modal_xs,
                .bazis-modal_small,
                .sl-modal_entrance
            ) {
            --padding-start-header: var(--bazis-padding-5x);
            --padding-end-header: 60px;
            --padding-start-content: var(--bazis-padding-5x);
            --padding-end-content: var(--bazis-padding-5x);
            --padding-start-footer: var(--bazis-padding-5x);
            --padding-end-footer: var(--bazis-padding-5x);
            --width: auto;
            margin: auto 40px;
        }
    }

    @media screen and (max-width: 768px) {
        &_small {
            --width: auto;
            margin: auto 40px;
        }
    }

    @media (max-width: 576px) {
        &:not(.mobile-bottom, .dummy, .sl-modal_entrance) {
            margin: auto var(--bazis-margin-5x);
        }

        bazis-button.sl-button_form {
            display: block;
        }

        &__close {
            bazis-icon.bh-hide-sm-down {
                display: block !important;
            }
            bazis-icon.bh-hide-sm-up {
                display: none;
            }
        }

        &__footer {
            box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.1);
        }

        app-modal-signature {
            .bazis-modal__footer {
                bazis-button {
                    display: block;
                }
            }
        }

        &.video {
            app-video-modal {
                display: flex;
                flex-direction: column;
                height: 100vh;
                justify-content: center;
            }
        }

        &.map {
            .sl-desktop-content {
                display: none;
            }

            .sl-mobile-content {
                .bazis-control {
                    --height: 34px;
                    --control-width: calc(100% - 8px);
                    margin-right: var(--bazis-margin-2x);
                }

                .bazis-list_short-info > .bazis-item {
                    --font-size: 16px;
                    --line-height: 24px;
                    --font-weight: 500;
                    --color: var(--bazis-text-color);
                }
            }
        }
    }
}

// types
.sl-modal {
    &_xs {
        --min-height: 401px;
        --max-height: 430px;
        --max-width: 664px;
        --padding-bottom-header: 0;
        --padding-top-content: 0;
        --padding-bottom-content: 0;
    }

    &_role {
        --max-width: 640px;
        --max-height: 599px;
        --padding-top-content: var(--bazis-padding-5x);
    }

    &_entrance {
        --top-close: 40px;
        --max-width: 394px;
    }

    &_clear {
        --background: transparent;
        --box-shadow: none;
        --max-height: 100%;
    }

    @media screen and (max-width: 768px) {
        &_xs {
            --width: auto;
            margin: auto 40px;
        }
    }
}
