.bazis-stepper {
    // --min-width: 213px;

    .bazis-item {
        --margin-start: 0;
    }

    bazis-step {
        --sign-color-active: var(--bazis-stepper-background);

        &.bazis-step_check {
            --sign-color-active: var(--bazis-stepper-background);
        }
    }

    .sl-header & {
        --margin-start: var(--bazis-margin-5x);
    }

    @media screen and (min-width: 920.98px) {
        --padding-start: 33px;
    }
}
