.bazis-contacts-control {
    &__group {
        --margin-bottom: var(--bazis-margin-2x);
        --background: transparent;

        app-edit-water-utility-drain-station & {
            --margin-bottom: var(--bazis-margin-8x);
        }
    }

    bazis-toolbar {
        --bazis-line-height-p-major: 36px;
    }
}
