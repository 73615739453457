.sl-main-page {
    --header-height: 56px;
    --bazis-background-medium: #f7f7f7;
    background-color: var(--bazis-background-medium);
    // position: relative;
    // top: calc(var(--header-height) * -1);
    // margin-bottom: calc(var(--header-height) * -1);

    .sl-section {
        --margin-top: 0;

        &__content {
            --padding-start: 40px;
            --padding-end: 40px;

            @media (max-width: 1024px) {
                --padding-start: 0;
                --padding-end: 0;
            }

            @media (max-width: 576px) {
                // --padding-start: 0;
                // --padding-end: 0;

                h2 {
                    --bazis-font-size-h2: 24px;
                    --bazis-line-height-h2: 32px;
                }
            }

            @media (hover: none) and (pointer: coarse) {
                .bazis-slider {
                    &__controls {
                        display: none;
                    }
                    &__indicator {
                        display: flex;
                    }
                }
            }

            @media (hover: hover) and (pointer: fine) {
                .bazis-slider {
                    &__controls {
                        display: block;
                    }
                    &__indicator {
                        display: none;
                    }
                }
            }
        }

        // 1 section
        &_banner {
            position: relative;
            overflow: hidden;

            &::after {
                content: '';
                display: block;
                position: absolute;
                z-index: 1;
                top: 0;
                left: 0;
                right: 0;
                height: 90px;
                background: linear-gradient(
                    180deg,
                    #08101c 0%,
                    #08151b 0.01%,
                    rgba(8, 33, 59, 0) 100%
                );
            }

            &__bg {
                // position: absolute;
                // width: 100%;
                // height: 100%;
                // top: 0;
                // bottom: 0;

                &::before,
                &::after {
                    content: '';
                    position: absolute;
                    width: 50%;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                }

                &::before {
                    left: 0;
                    background: linear-gradient(var(--dark), var(--light));
                }
                &::after {
                    right: 0;
                    background: linear-gradient(var(--dark-right) 5%, var(--light-right) 85%);
                }
            }

            .bazis-container {
                --dark: #091231;
                --light: #0d173a; //#122246;
                --dark-right: #14264a;
                --light-right: #1c3259;
                position: relative;
                // background: linear-gradient(70deg, #07151a, #062443); // 0B1435, 182A4E
                // background: radial-gradient(
                //         50% 100% at 34% bottom,
                //         var(--light),
                //         var(--dark),
                //         transparent
                //     ),
                //     linear-gradient(var(--dark-right) 5%, var(--light-right) 85%);
                // radial-gradient(
                //     50% 655px at bottom right,
                //     var(--light-right),
                //     var(--dark-right),
                // );

                &::before {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                    // left: -100%;
                    right: 100%;
                    background: linear-gradient(var(--dark), var(--light));
                }
                &::after {
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    bottom: 0;
                    // left: calc(100% + 275px);
                    left: calc(100% - 1px);
                    // right: -100%;
                    background: linear-gradient(var(--dark-right) 5%, var(--light-right) 85%);
                }
            }

            video,
            .sl-video-poster {
                position: absolute;
                z-index: 1;
                bottom: 0;
                left: calc(50% - 120px);
                transform: translateX(-50%);
                right: auto;
                width: auto;
                height: 100%;
                max-width: initial;
                // background: url('/assets/images/cover-banner.jpg') no-repeat center center;
                // background-size: cover;
            }

            .sl-section__content {
                --padding-top: 144px;
                --padding-bottom: 150px;
                --padding-start: 0;

                position: relative;
                z-index: 1;
                max-width: 620px; // 50% - 10px
                color: var(--bazis-text-color-contrast);
            }

            h1 {
                margin-bottom: 40px;
                padding-bottom: 0;

                + p {
                    font-size: 18px;
                    line-height: 28px;
                    margin-bottom: 40px;
                    opacity: 0.7;
                }
            }

            .sl-updates {
                --margin-bottom-list: 40px;
                align-items: baseline;

                .bazis-item:last-child {
                    display: inline-flex;
                    align-items: baseline;

                    &::before {
                        position: relative;
                        top: -2px;
                    }

                    span {
                        position: relative;
                        top: 1px;
                    }
                }
            }

            bazis-button {
                --height: 48px;
                font-size: 16px;
            }

            @media (max-width: 1280px) and (min-width: 1025px) {
                video,
                .sl-video-poster {
                    left: calc(50% - 250px);
                }
            }

            @media (max-width: 1024px) {
                .sl-section__content {
                    --padding-top: calc(var(--bazis-padding-6x) * 2);
                    --padding-bottom: 234px;
                    max-width: 490px;
                }
                h1 {
                    font-weight: 400;
                    --bazis-font-size-h1: 45px;
                    --bazis-line-height-h1: 52px;
                }
            }

            @media (max-width: 768px) {
                .bazis-container {
                    background: var(--bazis-header-background);
                }

                video,
                .sl-video-poster,
                .bazis-container::before,
                .bazis-container::after {
                    display: none;
                }

                .sl-section__content {
                    --padding-bottom: 138px;
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --padding-top: 88px;
                    --padding-bottom: 218px;
                    width: 100%;
                }
                .sl-updates {
                    display: block;

                    .bazis-item {
                        --padding-top: var(--bazis-padding-2x);
                        --padding-start: 0;

                        &::after {
                            display: none;
                        }

                        span {
                            top: 0;
                        }
                    }
                }
                h1 {
                    --bazis-font-size-h1: 28px;
                    --bazis-line-height-h1: 36px;
                    margin-top: 0;
                    margin-bottom: var(--bazis-margin-4x);
                }
                bazis-button {
                    display: block;
                }
            }
        }

        // 2.2 section
        &_about {
            .sl-section__content {
                --padding-top: 104px;
                text-align: center;

                .bazis-block {
                    --margin-bottom: 0;
                    --padding-top: 40px;
                    --padding-bottom: 40px;
                    --border-radius: 12px;
                    height: 100%;

                    .bazis-h6 a {
                        color: currentColor;
                    }
                }

                h2 {
                    --bazis-font-size-h2: 40px;
                    --bazis-line-height-h2: 48px;
                    padding-bottom: 35px; // 5px - col
                }

                h5 {
                    --bazis-font-size-h5: 18px;
                    --bazis-line-height-h5: 24px;
                }

                figure {
                    width: 144px;
                    margin: 0 auto var(--bazis-margin-8x);

                    img {
                        display: block;
                        border-radius: 50%;
                    }
                }

                .sl-grid_card {
                    margin: 0 calc(var(--bazis-padding-3x) * -1);

                    > bazis-row > bazis-col {
                        --bazis-grid-column-padding-start: var(--bazis-padding-3x);
                        --bazis-grid-column-padding-end: var(--bazis-padding-3x);
                    }
                }
            }

            @media (max-width: 1024px) {
                .sl-section__content {
                    .bazis-block {
                        --padding-start: var(--bazis-padding-5x);
                        --padding-end: var(--bazis-padding-5x);
                        width: calc(50% - 6px);
                        height: auto;

                        &:last-child() {
                            margin-right: 0;
                        }
                    }

                    .bazis-slider {
                        margin: 0 calc(var(--bazis-margin-5x) * -1);

                        &__content {
                            padding: var(--bazis-padding-5x);
                            gap: var(--bazis-margin-4x);
                        }
                    }
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --padding-top: 64px;

                    .bazis-block {
                        --padding-start: var(--bazis-padding-6x);
                        --padding-end: var(--bazis-padding-6x);
                    }

                    h2 {
                        --bazis-font-size-h2: 24px;
                        --bazis-line-height-h2: 32px;
                        padding-bottom: var(--bazis-padding-3x);
                    }
                    .bazis-block {
                        width: 100%;
                    }
                }
            }
        }

        // 3 section
        &_resolve {
            position: relative;
            overflow: hidden;
            background: url('/assets/images/bg-home-gal.svg') no-repeat calc(50% + 453px) -136px;

            .sl-section__content {
                --padding-top: 180px;
                --padding-bottom: 260px;
                position: relative;
                text-align: center;
            }

            h2 {
                max-width: 950px;
                margin: 0 auto var(--bazis-margin-6x);
                font-weight: 500;
            }

            p {
                --bazis-font-size-p: 18px;
                --bazis-line-height-p: 24px;
                --bazis-p-margin: 0 auto 40px;
                max-width: 720px;
            }

            bazis-button {
                --height: 48px;
                font-size: 16px;
            }

            .sl-imgs-wrap {
                img {
                    position: absolute;
                    object-fit: cover;
                    border-radius: 8px;

                    &.sl-imgs {
                        &__1 {
                            top: 315px;
                            left: 46px;
                            width: 82px;
                            height: 82px;
                            transform: rotate(-30.7deg);
                        }
                        &__2 {
                            top: 135px;
                            left: 40px;
                            width: 50px;
                            height: 50px;
                            transform: rotate(-15.47deg);
                        }
                        &__3 {
                            top: 65px;
                            left: 218px;
                            width: 71px;
                            height: 71px;
                            transform: rotate(-8.58deg);
                        }
                        &__4 {
                            top: 70px;
                            right: 296px;
                            width: 58px;
                            height: 58px;
                            transform: rotate(18.47deg);
                        }
                        &__5 {
                            top: 79px;
                            right: 28px;
                            width: 84px;
                            height: 84px;
                            transform: rotate(27.63deg);
                        }
                        &__6 {
                            top: 290px;
                            right: 67px;
                            width: 53px;
                            height: 53px;
                            transform: rotate(22.62deg);
                        }
                    }
                }
            }

            @media (max-width: 1280px) {
                .sl-section__content {
                    position: static;
                }
            }

            @media (max-width: 1024px) {
                background: transparent;

                .sl-imgs-wrap {
                    img.sl-imgs {
                        &__1 {
                            left: 65px;
                            transform: rotate(20deg);
                        }
                        &__2 {
                            left: 5px;
                        }
                        &__3 {
                            top: 45px;
                        }
                        &__5 {
                            right: -28px;
                        }
                    }
                }
            }

            @media (max-width: 940px) {
                .sl-imgs-wrap {
                    img.sl-imgs {
                        &__1 {
                            left: -34px;
                        }
                        &__2 {
                            left: -28px;
                        }
                        &__5 {
                            right: -47px;
                        }
                        &__6 {
                            right: -24px;
                        }
                    }
                }
            }

            @media (max-width: 660px) {
                .sl-section__content {
                    --padding-top: 344px;
                    --padding-bottom: 0;
                }

                h2 br {
                    display: none;
                }

                p {
                    --bazis-p-margin: 0 auto var(--bazis-margin-8x);
                }

                .sl-imgs-wrap {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;

                    img.sl-imgs {
                        &__1 {
                            top: 208px;
                            left: calc(50% - 88px / 2 - 116px);
                            transform: none;
                            width: 87px;
                            height: 87px;
                        }
                        &__2 {
                            top: 104px;
                            left: calc(50% - 64px / 2 - 156px);
                            transform: none;
                            width: 64px;
                            height: 64px;
                        }
                        &__3 {
                            top: 56px;
                            left: calc(50% - 96px / 2 + 36px);
                            transform: none;
                            width: 96px;
                            height: 96px;
                        }
                        &__4 {
                            top: 184px;
                            left: calc(50% - 48px / 2 - 16px);
                            right: auto;
                            transform: none;
                            width: 48px;
                            height: 48px;
                        }
                        &__5 {
                            top: 192px;
                            left: calc(50% - 72px / 2 + 112px);
                            right: auto;
                            transform: none;
                            width: 72px;
                            height: 72px;
                        }
                        &__6 {
                            top: 112px;
                            left: calc(50% - 56px / 2 + 160px);
                            right: auto;
                            transform: none;
                            width: 56px;
                            height: 56px;
                        }
                    }
                }

                bazis-button {
                    display: block;
                    width: 100%;
                    margin-bottom: var(--bazis-margin-8x);
                    margin-right: 0;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .bh-line-elements {
                    flex-direction: column;

                    bazis-button {
                        margin-bottom: 0;
                    }
                }
            }
        }

        // 4 section
        &_calculator {
            --margin-top: -135px;
            position: relative;

            h2 {
                --bazis-font-size-h2: 32px;
                --bazis-line-height-h2: 40px;
                margin-bottom: var(--bazis-margin-7x);
            }

            figure {
                margin: 0 auto var(--bazis-margin-8x);
                width: 320px;
            }

            .sl-section__content {
                .bazis-block {
                    --margin-bottom: 120px;
                    --padding-top: calc(var(--bazis-padding-6x) * 2);
                    --padding-start: calc(var(--bazis-padding-6x) * 2);
                    --padding-end: calc(var(--bazis-padding-6x) * 2);
                    --padding-bottom: calc(var(--bazis-padding-6x) * 2);
                    --border-radius: 12px;

                    position: relative;
                    background-color: var(--bazis-color-white);
                    background-image: url('/assets/images/calc-decor-repeat.png');
                    background-repeat: no-repeat;
                    background-position: calc(100% - 48px) 100%;
                    background-size: 466px 70px;

                    // --background: var(--bazis-color-white) url('/assets/images/calc-decor.png')
                    //     no-repeat calc(100% - 48px) 48px;
                    // background-size: auto 584px;
                    // background-size: 466px auto;

                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        right: 0;
                        width: 100%;
                        height: 100%;
                        background: url('/assets/images/calc-decor.png') no-repeat calc(100% - 48px)
                            48px;
                        // background-size: auto 584px;
                        background-size: 466px auto;
                    }

                    &__content {
                        width: 57%;
                    }
                }

                .bazis-datetime-control {
                    margin: 0;
                }

                .sl-form {
                    p:last-child {
                        margin-top: calc(var(--bazis-margin-6x) * 2);
                    }

                    &__submit {
                        --height: 52px;
                        max-width: 265px;
                        width: 100%;
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 24px;
                        letter-spacing: 0.5px;

                        &.bazis-btn--disabled {
                            bazis-icon {
                                transition-property: transform;
                                animation-name: rotate;
                                animation-duration: 1.2s;
                                animation-iteration-count: infinite;
                                animation-timing-function: linear;
                            }
                        }
                    }

                    &__footer {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0;
                        margin: 0;
                        border: 0;
                        gap: 16px;
                    }
                }

                td {
                    --padding-start: var(--bazis-padding-3x);
                    --padding-end: var(--bazis-padding-3x);
                    --padding-top: var(--bazis-padding-3x);
                    --padding-bottom: var(--bazis-padding-6x);

                    p.bazis-p_major {
                        --bazis-p-margin-major: 6px 0 var(--bazis-padding-1x);
                    }

                    label {
                        display: block;
                        margin: 6px 0;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    &:first-child {
                        --padding-start: 0;
                    }
                    &:last-child {
                        --padding-end: 0;
                    }
                }
            }

            .sl-result-price {
                flex-shrink: 0;
                text-align: right;
            }

            .sl-result {
                margin-bottom: var(--bazis-margin-2x);
                font-size: 20px;
                line-height: 22px;
            }

            .sl-fieldset {
                padding: var(--bazis-padding-4x) 0;
                border-bottom: 1px solid var(--bazis-border-color);

                label {
                    display: block;
                    margin-bottom: var(--bazis-margin-3x);
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            @media (max-width: 1380px) {
                .sl-section__content {
                    .bazis-block {
                        background-position: calc(100% - 24px) 100%;

                        &::before {
                            background-position: calc(100% - 24px) 48px;
                        }
                    }
                }
            }

            @media (max-width: 1320px) {
                .sl-section__content {
                    .bazis-block {
                        background-position: 100% 100%;

                        &::before {
                            background-position: 100% 48px;
                        }
                    }
                }
            }

            @media (max-width: 1235px) {
                .sl-section__content {
                    .bazis-block {
                        background-size: 40% 150px;

                        &::before {
                            background-size: 40% auto;
                        }
                    }
                }
            }

            @media (max-width: 1154px) and (min-width: 1024.98px) {
                .sl-section__content {
                    .bazis-block {
                        background-size: 40% 256px;
                    }
                }
            }

            @media (max-width: 1024px) {
                .sl-section__content {
                    .bazis-block {
                        background: var(--bazis-color-white);
                        --padding-start: 88px;
                        --padding-end: 88px;

                        &::before {
                            display: none;
                        }

                        &__content {
                            width: 100%;
                        }
                    }
                    .sl-form p:last-child {
                        margin-top: var(--bazis-margin-6x);
                    }
                }
                h2 {
                    text-align: center;
                    margin: 0 0 var(--bazis-margin-6x);
                }

                bazis-label {
                    --font-size: 14px;
                    --line-height: 20px;
                    font-weight: 400;
                }
            }

            @media (max-width: 768px) {
                .sl-section__content {
                    .bazis-block {
                        --padding-start: 40px;
                        --padding-end: 40px;
                    }
                }
            }

            @media (max-width: 576px) {
                --margin-top: 0;

                h2 {
                    --bazis-font-size-h2: 24px;
                    --bazis-line-height-h2: 32px;
                    margin-bottom: var(--bazis-margin-4x);
                    text-align: left;
                }

                .sl-section__content {
                    --padding-top: 72px;
                    // --padding-bottom: 64px;

                    .bazis-block {
                        --padding-top: var(--bazis-padding-8x);
                        --padding-bottom: var(--bazis-padding-8x);
                        --padding-start: var(--bazis-padding-5x);
                        --padding-end: var(--bazis-padding-5x);
                        --margin-bottom: calc(var(--bazis-margin-8x) * 2);
                        // flex-direction: column-reverse;
                    }

                    .sl-form__footer {
                        flex-direction: column-reverse;
                        align-items: flex-start;

                        bazis-button {
                            display: block;
                            max-width: 100%;
                        }
                    }
                }

                .sl-result {
                    font-size: 14px;
                    line-height: 20px;

                    .bh-font-medium {
                        font-size: 20px;
                        line-height: 24px;
                    }

                    &-price {
                        margin-bottom: var(--bazis-margin-6x);
                        text-align: left;
                    }
                }
            }
        }

        // 7 section
        &_faq {
            position: relative;

            .sl-section_faq__bg {
                overflow: hidden;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                height: 100%;
                width: 100%;

                background: url('/assets/images/home-faq-bg.jpg') 50% 0 no-repeat,
                    url('/assets/images/home-faq-repeat.jpg') 50% 0 repeat-y,
                    var(--bazis-background-dark);
                background-size: 1440px 813px, 1440px 1px;
            }

            .bazis-container_margin {
                position: relative;
            }

            .sl-section__content {
                --padding-top: 120px;
                --padding-bottom: 120px;
                --padding-start: 0;
                max-width: 650px;
            }

            h2 {
                margin: 0 0 40px;
                --bazis-font-size-h2: 32px;
                --bazis-line-height-h2: 40px;
            }

            @media (min-width: 1435px) {
                .sl-section_faq__bg {
                    &::before {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        bottom: 0;
                        right: calc(100% - (100% - 1360px) / 2 + 40px);
                        // right: calc(100% - 38px);
                        // right: calc(50% + var(--padding-side));
                        background: url('/assets/images/home-faq-repeat-left.jpg') 0 0 repeat-x;
                        background-size: 1px 813px;
                    }
                    &::after {
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        bottom: 0;
                        left: calc(100% - (100% - 1360px) / 2 + 40px);
                        // left: calc(100% - 38px);
                        // left: calc(50% + var(--padding-side));
                        background: url('/assets/images/home-faq-repeat-right.jpg') 0 0 repeat-x;
                        background-size: 1px 813px;
                    }
                }
            }

            @media (max-width: 1280px) and (min-width: 1025px) {
                .sl-section_faq__bg {
                    background: url('/assets/images/home-faq-bg.jpg') 50% 0 no-repeat;
                    background-size: cover;
                }
            }

            @media (max-width: 1024px) {
                .sl-section_faq__bg {
                    background: transparent;
                }

                .bazis-container_margin {
                    &::before,
                    &::after {
                        display: none;
                    }
                }

                .sl-section__content {
                    --padding-top: 0;
                    max-width: 100%;
                }
            }

            @media (max-width: 576px) {
                .sl-section__content {
                    --padding-top: 64px;
                    --padding-start: 0;
                    --padding-end: 0;

                    h2 {
                        --bazis-font-size-h2: 24px;
                        --bazis-line-height-h2: 32px;
                        margin-bottom: var(--bazis-margin-8x);
                    }
                }
            }
        }
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
