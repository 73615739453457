app-modal-create-task bazis-select-search + .bazis-block_secondary {
    margin-top: calc(var(--bazis-margin-2x) * -1);
}

// .sl-logs {
//     word-wrap: break-word;
//     overflow-y: auto;
//     max-height: 400px;
// }

// app-profile-license-address {
//     .sl-toolbar_table {
//         --padding-top: var(--bazis-padding-4x);
//         --padding-bottom: var(--bazis-padding-4x);

//         &::part(start) {
//             width: 100%;
//         }

//         [slot=start] {
//             display: block;
//         }
//     }
//     // .bazis-paginator_secondary {
//     //     padding-bottom: var(--bazis-padding-5x);
//     // }

//     .bazis-page {
//         --padding-bottom: var(--bazis-padding-5x);
//     }
// }
