.bazis-paginator {
    @media (max-width: 1024px) {
        &,
        &_secondary {
            margin-top: var(--bazis-margin-5x);
        }
    }

    @media (max-width: 576px) {
        &,
        &_secondary {
            .sl-paginator__total {
                line-height: 36px;
            }
        }
    }
}
