.bazis-dateplate {
    h5 &,
    h6 & {
        bazis-icon {
            margin-top: -2px;
        }
    }

    &__time bazis-icon {
        --bazis-icon-xs-size: 16px;
    }
}
