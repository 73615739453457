.bazis-btn_small {
    &.bazis-btn_outline {
        bazis-icon[slot='icon-only'] {
            margin-left: -5px;
            margin-right: -5px;
        }
        @supports (margin-inline-start: 0) or (-webkit-margin-start: 0) {
            bazis-icon[slot='icon-only'] {
                margin-left: unset;
                margin-right: unset;
                -webkit-margin-start: -5px;
                margin-inline-start: -5px;
                -webkit-margin-end: -5px;
                margin-inline-end: -5px;
            }
        }
    }
}

bazis-button.sl-button_nowrap {
    width: 100%;
}

aside .sl-dropdown_action + bazis-button.sl-button_nowrap {
    width: calc(100% - 52px);
}
