.bazis-btn-group {
    &__item.bh-hidden + &__item {
        bazis-button.bazis-btn_solid {
            &::part(native) {
                border-left: var(--border-width) var(--border-style) var(--border-color);
            }
            &.bazis-btn--active::part(native) {
                border-left-color: var(--background-activated);
            }

            &:hover::part(native),
            &:focus-visible::part(native) {
                border-left-color: var(--background-hover);
            }
        }
    }

    @media (max-width: 576px) {
        bazis-input-options-button & bazis-button {
            --padding-start: var(--bazis-margin-2x);
            --padding-end: var(--bazis-margin-2x);
        }
    }
}
