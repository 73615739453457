.sl-landing-page {
    background-color: var(--bazis-background-medium);

    .sl-section {
        --margin-top: 0;

        h2 {
            font-weight: 500;
            text-align: center;
        }

        &__content {
            --padding-start: 40px;
            --padding-end: 40px;

            @media (max-width: 1024px) {
                --padding-start: 0;
                --padding-end: 0;

                h2 {
                    --bazis-font-size-h2-major: 32px;
                    --bazis-line-height-h2-major: 40px;
                }
            }

            @media (max-width: 576px) {
                h2 {
                    --bazis-font-size-h2-major: 24px;
                    --bazis-line-height-h2-major: 32px;
                    --bazis-h2-margin-major: 0 0 var(--bazis-margin-8x);
                    text-align: left;
                }
            }
        }

        &_faq {
        }

        @media (max-width: 576px) {
        }
    }
}
