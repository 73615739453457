.bazis-tab-status {

    .os-Windows & {
        position: relative;
        top: 3px;
    }

    &__item {
        --padding-top: calc(var(--bazis-margin-2x) - 1px);
        --padding-bottom: calc(var(--bazis-margin-2x) - 1px);

        line-height: 16px;

        bazis-button {
            // --height: 16px;
            --height-xs: 16px;
            --padding-top: 0;
            --padding-bottom: 0;
            --color: var(--bazis-color-action);
            font-size: 10px;
            letter-spacing: 1px;

            &.bazis-btn_has-icon-only {
                --padding-start: 0;
                --padding-end: 0;
            }
        }

        &--active {
            bazis-button {
                --color: var(--bazis-color-action-contrast);
            }
        }

        &:hover {
            bazis-button {
                --color: var(--bazis-color-action);
            }
        }
    }

    bazis-menu-input-date.bazis-tab-status__item {
        display: flex;
        align-items: center;
        line-height: 11px;

        > * {
            border-bottom: 0;
        }
    }

    @media (max-width: 1024px) {
        --margin-start: 0;
        --margin-bottom: var(--bazis-margin-6x);
    }
}
