.bazis-list {

    &_short-info {
        bazis-infinite-list & {
            // flex-wrap: wrap;
            display: inline-block;

            > .bazis-item {
                min-height: initial;
                display: inline;
            }
        }
    }

    &_vline {
        --line-height: 16px;
    }

    &_select {
        .sl-dropdown_action &,
        .bazis-accordion_doc .bazis-dropdown &,
        .bazis-map__base-control &,
        .bazis-signed-file-inline & {
            --border-radius-list: var(--bazis-border-radius);
            --border-width-list: 1px;
            --border-style-list: solid;
            --border-color-list: var(--bazis-background-medium);
            --padding-top-list: var(--bazis-padding-3x);
            --padding-bottom-list: var(--bazis-padding-3x);
            --box-shadow: 0px 4px 24px -10px rgba(0, 0, 0, 0.1);
        }

        .bazis-dropdown & {
            bazis-button.bazis-item {
                --background: transparent;
                --background-hover: var(--bazis-background-dark);
                --padding-start: var(--bazis-padding-3x);
                --padding-end: var(--bazis-padding-3x);
                --padding-top: var(--bazis-padding-2x);
                --padding-bottom: var(--bazis-padding-2x);
                text-align: left;

                &::part(inner) {
                    --padding-start: 0;
                    --padding-end: 0;
                    justify-content: flex-start;
                }

                &:active:hover {
                    &::part(inner) {
                        background: var(--background-selected);
                        color: var(--color-selected);
                    }
                }
            }
            bazis-switcher.bazis-item {
                --background: transparent;
                --background-hover: var(--bazis-background-dark);
                --background-selected: var(--background-hover);
                --color-selecte: var(--color-hover);

                .bazis-switcher__label {
                    --font-weight: 400;
                    --font-size: 14px;
                    --line-height: 20px;
                }

                bazis-toggle:not(.bazis-toggle--checked) + .bazis-switcher__label {
                    color: var(--bazis-text-color-secondary);
                }
            }
        }
    }

    &_gallery {
        > .bazis-item {
            --margin-end: var(--bazis-margin-1x);
        }

        .bazis-figure {
            // --width: 30.64%;
            --width: 271px;

            &_medium {
                // --width: 68.28%;
                --width: 604px;
            }
        }
    }

    &_history {
        --width-bullet: 8px;

        > .bazis-item {
            --margin-bottom: var(--bazis-margin-8x);
            --padding-start: calc(var(--bazis-padding-4x) + var(--width-bullet) + 2px);

            &::before {
                top: 4.5px;
            }

            &::after {
                top: 14.5px;
                left: 4.5px;
            }

            &:last-child {
                --margin-bottom: 0;

                &::after {
                    display: block;
                    bottom: 0;
                }
            }
        }
    }

    &_e-sign {
        --margin-bottom: var(--bazis-margin-8x);

        dl.bazis-dl_inline {
            --margin-top-dl: var(--bazis-margin-4x);
            --margin-bottom-dl: var(--bazis-margin-4x);
        }

        @media screen and (max-width: 1024px) {
            .bazis-item .bh-flex {
                flex-direction: column;
            }

            .bazis-e-sign {
                margin-bottom: var(--bazis-margin-6x);
            }
        }
    }
}

.sl-list {
    &_easy {
        > .bazis-item + .bazis-item {
            --padding-top: var(--bazis-padding-4x);

            app-profile-licenses & {
                --padding-top: var(--bazis-padding-6x);
            }
        }

        > .bazis-item {
            bazis-tooltip & {
                --padding-top: var(--bazis-padding-3x);
            }
        }
    }

    &_trip {
        @extend .bazis-list_history;
        --margin-top-list: var(--bazis-margin-3x);

        > .bazis-item {
            --padding-start: calc(var(--width-bullet) + var(--bazis-margin-4x) + 1px);
            --margin-bottom: var(--bazis-margin-5x);
            // --font-size: 13px;
            // --line-height: 16px;

            &::before {
                top: 4.5px;
                background: var(--bazis-color-base, var(--bazis-placeholder-color));
                // border: 2px solid var(--bazis-color-primary);
            }

            &::after {
                left: 4.5px;
                top: 14.5px;
                // bottom: calc(var(--margin-bottom) * -1 + 3px);
            }
            &:last-child {
                --margin-bottom: 0;

                &::before {
                    border-color: var(--bazis-color-base, var(--bazis-placeholder-color));
                }
                &::after {
                    display: none;
                }
            }
        }
    }

    &_vtabs {
        --color: var(--bazis-text-color);
        // --color-hover: var(--color);
        // --color-selected: var(--color);
        --font-size: 13px;
        --font-weight: 400;
        --line-height: 20px;
        --padding-start: calc(var(--bazis-padding-4x) - 2px);
        --padding-end: var(--bazis-padding-5x);
        --padding-top: var(--bazis-padding-2x);
        --padding-bottom: var(--bazis-padding-2x);
        --border-width: 0 0 0 2px;
        --border-color: transparent;
        --border-style: solid;
        --border-radius: 0;

        aside & {
            --margin-start-list: calc(var(--bazis-margin-4x) * -1);
            --margin-top-list: var(--bazis-margin-2x);
        }

        aside.sl-aside_left & {
            --margin-start-list: calc(var(--bazis-margin-5x) * -1);
        }

        .bazis-item {
            cursor: pointer;

            &--active {
                --font-weight: 500;
                --border-color: var(--bazis-text-color);
            }
        }
    }
}
