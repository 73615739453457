.sl-scheduler {
    @include props-width;

    @include width;
}

.sl-scheduler {
    --margin-bottom-list: var(--bazis-margin-3x);
    --width: 348px;
    --max-width: 100%;

    &.bazis-list {
        --padding-top: var(--bazis-padding-1x);
        --padding-bottom: var(--bazis-padding-1x);

        .sl-scheduler__day {
            display: inline-block;
            width: 22px;

            + span {
                margin-left: var(--bazis-margin-3x);
            }
        }
    }

    @media (max-width: 780px) {
        --width: 100%;
    }

    @media (max-width: 576px) {
        --margin-bottom-list: 0;

        &.bazis-list  {
            .bazis-item {
                display: block;

                > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                > span {
                    display: block;
                    text-align: right;
                }
            }
        }
    }
}
