.bazis-infinite-scroll-content {
    .sl-filter app-search-filter .bazis-list_control-dd & {
        padding-bottom: 60px;

        .bazis-item + bazis-button {
            position: fixed;
            z-index: 150;
            bottom: 12px;
            left: 20px;
            right: 20px;
        }

        @media (min-width: 992.98px) {
            overflow: auto;
            height: 182px;
        }
    }
}
